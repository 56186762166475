import React from "react"
import Post from "./Post/Post"
import Banner from "../Banner/Banner"
import Container from "../Container/Container"

import "./Posts.css"

const Posts = ({ posts, title }) => {
  // ページング機能がなくて全部表示なので使いにくい.

  return (
    <Container>
      <section className="posts">
        <p className="main-title">{title}</p>
        <div className="posts-center">
          {/* posts column */}
          <article>
            {posts.map(post => {
              return <Post key={post.id} {...post} />
            })}
          </article>

          {/* banner column */}
          <article>
            <Banner />
          </article>
        </div>
      </section>
    </Container>
  )
}

export default Posts
