import React from "react"
import Layout from "../components/Layout"
import Posts from "../components/Posts/Posts"
import { graphql } from "gatsby"
import Container from "../components/Container/Container"

import "../assets/css/home.css"

const IndexPage = ({ data }) => {
  const {
    allMdx: { nodes: posts },
  } = data

  return (
    <Layout>
      <Container>
        <div className="top-header">
          <h1 className="top-title">Dev Sec Note</h1>
          <img
            src="/top-title.gif"
            alt="Dev Sec Note"
            className="top-title-img"
          />
          <h2 className="sub-title">
            開発、セキュリティに関する個人ノートです
          </h2>
        </div>
        <Posts posts={posts} title="PICK UP" />
        <div className="forSpace" style={{ height: "50px" }} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    allMdx(limit: 3, sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        excerpt
        frontmatter {
          title
          author
          category
          date(formatString: "YYYY-MM-DD")
          slug
          readTime
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        id
      }
    }
  }
`

export default IndexPage
